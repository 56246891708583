import { Badge } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

interface ISuperUserBadgeProps {
  merchantView?: boolean;
}

const SuperUserBadgeWrapper = styled.div<ISuperUserBadgeProps>`
  margin-right: 7px;

  & .Polaris-Badge {
    background: ${({ merchantView }) => (merchantView ? "#FFFFFF33" : "#CEF31E")};
    color: ${({ merchantView }) => (merchantView ? "#fff" : "#3B1E33")};
    font-weight: 400;
    height: 20px;
    white-space: nowrap;
  }
`;

export const SuperuserBadge = ({ merchantView }: ISuperUserBadgeProps) => (
  <SuperUserBadgeWrapper merchantView={merchantView}>
    <Badge>{merchantView ? "Merchant view" : "⚡️ Super user"}</Badge>
  </SuperUserBadgeWrapper>
);
